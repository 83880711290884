const routes = {
    HOME: '/',
    CREATE_MENU: '/new',
    EDIT_MENU: '/edit',
    VIEW_MENU: '/view/:menuId'
};

// export function getIdRoute(route: any, id: any) {
//     return route.replace(":menuId", id);
// }

export default routes;