import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
// import { Redirect } from 'react-router-dom';
import routes from './router/routes';
import ErrorBoundary from './utils/ErrorBoundary'
// import ViewMenu from './pages/menu/ViewMenu'
import useGaTracker from './GaTracker'

// const HomePage = lazy(() => import('./pages/home/index'))
const ViewMenu = lazy(() => import('./pages/menu/ViewMenu'))

const App = () => {
  useGaTracker()
  
  return (
    <ErrorBoundary>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          {/* <Route exact path={routes.HOME}  component={HomePage} /> */}
          <Route path={routes.VIEW_MENU} component={ViewMenu} />
        </Switch> 
        {/* <Switch>
          <Redirect exact from="/" to={routes.VIEW_MENU} />
          <Route path={routes.VIEW_MENU} component={ViewMenu} />
        </Switch> */}
    
        {/* <Switch>
          <Route exact path="/" >
            <Redirect to={routes.VIEW_MENU} />
          </Route>
          <Route exact path={routes.VIEW_MENU} component={ViewMenu} />
        </Switch> */}

        {/* <Switch>
          <Route path={routes.HOME} exact component={HomePage} />
          <Route path={routes.CREATE_MENU} component={CreateMenu} />
          <Route path={routes.EDIT_MENU} component={EditMenu} />
          <Route path={routes.VIEW_MENU} exact component={ViewMenu} />
          <Route path={routes.DOWNLOAD_QRCODE} component={QRCodePage} />
        </Switch> */}
      </Suspense>
    </ErrorBoundary>
  );
}

// const App = () => {
//   return (
//     <ErrorBoundary>
//       <Suspense fallback={<div>Loading...</div>}>
//         <Container style={{ margin: 20 }}>
//           <Page>
//           </Page>
//         </Container>
//       </Suspense>
//     </ErrorBoundary>
//   )
// }

export default App;
